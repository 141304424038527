<template>
  <table class="table caption-top">
    <caption class="text-white text-center">
      Officers
    </caption>
    <thead>
      <tr></tr>
    </thead>
    <tbody>
      <tr v-for="officer in officers" :key="officer">
        <td class="text-white left-align cell-padding td-background">
          {{ officer.Office }}
        </td>
        <td class="text-white left-align cell-padding td-background">
          {{ officer.Name }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    officers: Array,
  },
};
</script>

<style scoped>
/* Your table styling goes here */
.left-align {
  text-align: left;
}
.cell-padding {
  padding-left: 25px;
}
.table-background {
  background-color: transparent;
}
.td-background {
  background-color: transparent;
}
</style>
