<template>
  <div>
    <div v-if="filteredCalendarEvents && filteredCalendarEvents.length > 0">
      <div
        class="row align-items-center justify-content-center"
        v-for="item in filteredCalendarEvents"
        :key="item"
      >
        <h2 class="text-white">
          {{ item.summary }}
          {{ format_dateLong(item.start?.dateTime) }}
        </h2>
      </div>
    </div>
    <div v-else>
      <h2 class="text-white">
        No Events Scheduled for {{ format_dateLong(new Date()) }}
      </h2>
    </div>
  </div>
</template>

<script>
import { format, utcToZonedTime } from "date-fns-tz";
export default {
  props: {
    filteredCalendarEvents: Array,
  },
  methods: {
    format_dateLong(value) {
      if (value) {
        return format(
          utcToZonedTime(
            value,
            Intl.DateTimeFormat().resolvedOptions().timeZone
          ),
          "MMMM dd yyyy, h:mm a"
        );
      }
    },
  },
};
</script>
