<template>
  <div>
    <h1
      class="text-white"
      v-if="filteredCalendarEvents && filteredCalendarEvents.length > 0"
    >
      Welcome to {{ filteredBodyList[0].BodyName }}
    </h1>
    <h1 class="text-white" v-else-if="iDefaultBodyDisplayName">
      Welcome to {{ iDefaultBodyDisplayName }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    filteredCalendarEvents: Array,
    filteredBodyList: Array,
    iDefaultBodyDisplayName: String,
  },
};
</script>
