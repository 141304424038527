<template>
  <div class="bg-image position-relative" :style="bgi">
    <div
      class="mask position-absolute top-0 end-0 bottom-0 start-0"
      style="
        background: linear-gradient(
          45deg,
          hsla(0, 0%, 0%, 0.6),
          hsla(0, 0%, 0%, 0.95) 100%
        );
      "
      v-if="filteredCalendarEvents && filteredCalendarEvents.length > 0"
    >
      <WelcomeMessage
        :filteredCalendarEvents="filteredCalendarEvents"
        :filteredBodyList="filteredBodyList"
      />
      <Events :filteredCalendarEvents="filteredCalendarEvents" />
      <div class="row">
        <div class="col"></div>
        <div class="col-5">
          <OfficersTable :officers="filteredBodyList" />
        </div>
        <div class="col"></div>
      </div>
    </div>
    <div
      class="mask position-absolute top-0 end-0 bottom-0 start-0"
      style="
        background: linear-gradient(
          45deg,
          hsla(0, 0%, 0%, 0.6),
          hsla(0, 0%, 0%, 0.95) 100%
        );
      "
      v-else
    >
      <WelcomeMessage :iDefaultBodyDisplayName="iDefaultBodyDisplayName" />
      <Events :filteredCalendarEvents="filteredCalendarEvents" />
      <div class="row">
        <div class="col"></div>
        <div class="col-6">
          <OfficersTable :officers="filterDefaultOfficers" />
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Events from "./EventsSection.vue";
import WelcomeMessage from "./WelcomeMessage.vue";
import OfficersTable from "./OfficerTable.vue";
import axios from "axios";
import { format } from "date-fns-tz";
import config from "/config.json";
export default {
  components: { WelcomeMessage, Events, OfficersTable },
  name: "SignApp",
  props: {
    msg: String,
  },
  data() {
    return {
      iDefaultBodyDisplayName: config.DEFAULT_BODY_DISPLAY_NAME,
      iofficerData: null,
      icalendarData: null,
      iDefaultOfficerData: null,
      iimageUrls: null,
      idefaultImage: null,
      currentDate: format(new Date(), "yyyy-MM-dd"), // Get current date in 'YYYY-MM-DD' format
      // currentDate: format(new Date("2024-02-13T19:00:00-06:00"), "yyyy-MM-dd"), // Get a set date in 'YYYY-MM-DD' format
    };
  },
  methods: {
    async officerData() {
      try {
        const response = await axios.get(
          `https://opensheet.elk.sh/${config.OFFICER_UUID}/${config.OFFICER_SHEET}`
        );
        this.iofficerData = response.data;
        this.iDefaultOfficerData = response.data.filter(
          (officer) => officer.Body === config.DEFAULT_BODY
        );
      } catch (error) {
        console.log("CONFIG FILE", config);
        console.error("Error fetching officer data:", error);
      }
    },

    async calendarData() {
      try {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 14); // 2 weeks prior  //prod
        // startDate.setDate(startDate.getDate("2024-02-13T19:00:00-06:00") - 14); // 2 weeks prior //dev

        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 14); // 2 weeks after //prod
        // endDate.setDate(endDate.getDate("2024-02-13T19:00:00-06:00") + 14); // 2 weeks after //dev
        // prettier-ignore
        const url = `https://www.googleapis.com/calendar/v3/calendars/${config.CALENDAR_ID}/events?key=${config.CALENDAR_KEY}&timeMin=${startDate.toISOString()}&timeMax=${endDate.toISOString()}&singleEvents=True`;

        const response = await axios.get(url);
        this.icalendarData = response.data;
        // console.log(
        //   "🚀 ~ calendarData ~ this.icalendarData:",
        //   this.icalendarData
        // );
      } catch (error) {
        console.error("Error fetching calendar data:", error);
      }
    },
    async imageUrls() {
      try {
        const response = await axios.get(
          "https://opensheet.elk.sh/1ux6wSVunSvu9HjR8K7PZXHOOE3hkiWgE5u8PimI2-1M/Images"
        );
        this.iimageUrls = response.data;
        this.idefaultImage = response.data
          .filter((wImage) => wImage.Body === config.DEFAULT_IMAGE)
          .map((image) => image.Thumbnail);
        // console.log(this.iimageUrls);
        // console.log("DEFAULT IMAGE CALL", this.idefaultImage);
      } catch (error) {
        console.error("Error fetching image urls:", error);
      }
    },
  },
  created() {
    this.officerData();
    this.calendarData();
    this.imageUrls();
  },
  mounted() {
    this.interval = setInterval(() => {
      this.officerData();
      this.calendarData();
      this.imageUrls();
    }, 30000);
  },
  computed: {
    bgi() {
      // console.log("DEFAULT IMAGE IN BGI", this.idefaultImage);
      const defaultImage = this.idefaultImage;
      //  "https://drive.google.com/thumbnail?id=1vKshCTHr7Ub6_ca287pxS3VxWO9DxKNH";
      // "https://drive.google.com/thumbnail?id=1AKBa2MEJNVC5gz-Oo1zYUK6FFsDBtks0";
      const thumbnailUrl =
        this.filteredImageUrlList.length > 0
          ? this.filteredImageUrlList[0].Thumbnail
          : defaultImage;
      // : // : this.idefaultImage[0].Thumbnail; // Provide a default URL if the list is empty
      return {
        background: "url(" + thumbnailUrl + ") center/cover",
        height: "120vh",
      };
    },
    filteredCalendarEvents() {
      if (this.icalendarData && this.icalendarData.items) {
        // Filter calendar events for today
        return this.icalendarData.items.filter((item) => {
          const eventDate = item.start?.dateTime?.split("T")[0];
          return eventDate === this.currentDate;
        });
      } else {
        return [];
      }
    },
    filteredBodyList() {
      return (
        this.filteredCalendarEvents &&
        this.filteredCalendarEvents.reduce((filtered, event) => {
          const bodyFromSummary = event.summary?.substring(0, 5);
          // console.log("Calendar List", this.filteredCalendarEvents);
          // console.log("Body from summary", bodyFromSummary);

          const matchingOfficers = this.iofficerData.filter((item) => {
            const lowerCaseBody = item.Body?.toLowerCase();
            const startsWithMatch = lowerCaseBody?.startsWith(
              bodyFromSummary?.toLowerCase()
            );

            // console.log("Matching Officer", item, startsWithMatch);

            return startsWithMatch;
          });

          if (matchingOfficers.length > 0) {
            filtered.push(...matchingOfficers);
          }
          // console.log("filteredBodyList", filtered);
          return filtered;
        }, [])
      );
    },
    filterDefaultOfficers() {
      return this.iDefaultOfficerData;
    },
    filteredImageUrlList() {
      return (
        this.filteredCalendarEvents &&
        this.filteredCalendarEvents.reduce((filtered, event) => {
          const bodyFromSummary = event.summary?.substring(0, 5);
          // console.log("Calendar List for Images", this.filteredCalendarEvents);
          // console.log("Body from summary for Images", bodyFromSummary);

          const matchingImages = this.iimageUrls.filter((item) => {
            const lowerCaseBody = item.Body?.toLowerCase();
            const startsWithMatch = lowerCaseBody?.startsWith(
              bodyFromSummary?.toLowerCase()
            );

            // console.log("Matching Officer", item, startsWithMatch);

            return startsWithMatch;
          });

          if (matchingImages.length > 0) {
            filtered.push(...matchingImages);
          }

          return filtered;
        }, [])
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.left-align {
  text-align: left;
}
.cell-padding {
  padding-left: 25px;
}
</style>
